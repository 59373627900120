import { FC, ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '@mui/material';

import Header from '@/components/LandingMozza/common/StartingSteps/Header/Header';
import { StepVariant } from '@/components/LandingMozza/common/StartingSteps/StartingSteps.type';
import Step from '@/components/LandingMozza/common/StartingSteps/Step/Step';
import Button from '@/designSystem/Button/Button';
import Enter from '@/generic/Enter/Enter';

import messages from './messages';

import useStyles from './styles';

type Props = {
  title?: string | ReactElement;
  subtitle?: string | ReactElement;
  steps?: { title: string | ReactElement; content: string | ReactElement }[];
  handleRedirection: () => void;
};

const stepVariants: StepVariant[] = ['yellow', 'blue', 'red', 'green'];
const StartingSteps: FC<Props> = ({
  title,
  subtitle,
  steps,
  handleRedirection,
}) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();

  return (
    <>
      <Header title={title} subtitle={subtitle} />
      <div className={classes.steps}>
        {steps
          ? steps.map(({ title, content }, index) => (
              <Step
                key={`step-${index}`}
                stepIndex={index + 1}
                title={title}
                content={content}
                stepVariant={stepVariants[index]}
              />
            ))
          : stepVariants.map((stepVariant, index) => (
              <Step
                key={`step-${index}`}
                stepIndex={index + 1}
                title={
                  <FormattedMessage
                    {...messages[`title${index + 1}`]}
                    values={{
                      enter: (chunk) => <Enter>{chunk}</Enter>,
                      color: (chunk) => (
                        <Typography
                          component="span"
                          variant="titleLg"
                          className={classes[stepVariant]}
                        >
                          {chunk}
                        </Typography>
                      ),
                    }}
                  />
                }
                content={
                  <FormattedMessage {...messages[`description${index + 1}`]} />
                }
                stepVariant={stepVariant}
              />
            ))}
      </div>
      <div className={classes.buttonContainer}>
        <Button
          variant="primary"
          size="large"
          onClick={handleRedirection}
          label={formatMessage(messages.button)}
        />
      </div>
    </>
  );
};

export default StartingSteps;
