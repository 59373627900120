import rem from 'polished/lib/helpers/rem';
import { CSSObject } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

const COMMON_STYLE = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: rem(8),
} as CSSObject;

export default makeStyles<{
  canStopRedirectToRating: boolean;
}>()(({ palette }, { canStopRedirectToRating }) => ({
  container: {
    ...COMMON_STYLE,
    width: '100%',
    cursor: canStopRedirectToRating ? 'unset' : 'pointer',
    padding: `${rem(4)} 0`,
  },
  google: {
    ...COMMON_STYLE,
  },
  stars: {
    display: 'flex',
    color: palette.supporting.amber[500],
  },
}));
