import { FC, ReactElement, useMemo } from 'react';

import Counter from '@/components/LandingMozza/common/StartingSteps/Counter/Counter';
import Description from '@/components/LandingMozza/common/StartingSteps/Description/Description';
import { StepVariant } from '@/components/LandingMozza/common/StartingSteps/StartingSteps.type';
import {
  CARD_SPACING,
  MIN_SCALE_PERCENT,
  NAVBAR_HEIGHT,
  NAVBAR_MARGIN,
  SCALE_FACTOR,
  TRIGGER_ANIMATION_SPACING,
} from '@/components/LandingMozza/common/StartingSteps/Step/constants';
import StepImage from '@/components/LandingMozza/common/StartingSteps/StepImage/StepImage';
import Title from '@/components/LandingMozza/common/StartingSteps/Title/Title';
import useIsElementBeyond from '@/hooks/animation/useIsElementBeyond';

import useStyles from './styles';

type Props = {
  title: string | ReactElement;
  content: string | ReactElement;
  stepVariant: StepVariant;
  stepIndex: number;
};

const Step: FC<Props> = ({ title, content, stepIndex, stepVariant }) => {
  const stickyTopY = useMemo(
    () => NAVBAR_HEIGHT + NAVBAR_MARGIN + CARD_SPACING * stepIndex,
    [stepIndex]
  );
  const { classes, cx } = useStyles({
    stickyTop: stickyTopY,
    scalePercent: MIN_SCALE_PERCENT + stepIndex * SCALE_FACTOR,
  });
  const { isElementBeyond, ref } = useIsElementBeyond({
    y: stickyTopY + TRIGGER_ANIMATION_SPACING,
  });

  return (
    <div
      ref={ref}
      className={cx(classes.container, !isElementBeyond && classes.scaleDown)}
    >
      <div
        className={cx(classes.leftSection, classes[`${stepVariant}LeftPanel`])}
      >
        <Counter
          stepIndex={stepIndex}
          stepVariant={stepVariant}
          className={classes.counter}
        />
        <Title title={title} className={classes.title} />
        <Description content={content} />
      </div>
      <div
        className={cx(
          classes.rightSection,
          classes[`${stepVariant}RightPanel`]
        )}
      >
        <StepImage stepIndex={stepIndex} />
      </div>
    </div>
  );
};

export default Step;
