import { FC, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import ArticleHeader from '@/components/LandingMozza/common/ArticleHeader/ArticleHeader';
import SectionTitle from '@/components/LandingMozza/common/SectionTitle/SectionTitle';
import TypographyUnderlined from '@/components/LandingMozza/LandingMozza/common/TypographyUnderlined/TypographyUnderlined';
import EnterChunk from '@/components/ReactIntlChunks/EnterChunk';

import messages from './messages';

interface Props {
  title?: string | ReactElement;
  subtitle?: string | ReactElement;
}

const Header: FC<Props> = ({ title, subtitle }) => (
  <ArticleHeader>
    <SectionTitle>
      {title ?? (
        <FormattedMessage
          {...messages.title}
          values={{
            enter: EnterChunk,
            underlined: (chunks) => (
              <TypographyUnderlined>
                <span>{chunks}</span>
              </TypographyUnderlined>
            ),
          }}
        />
      )}
    </SectionTitle>
    <Typography component="p" variant="bodyXl">
      {subtitle ?? <FormattedMessage {...messages.description} />}
    </Typography>
  </ArticleHeader>
);

export default Header;
