import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette, breakpoints }) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: palette.tertiary.main,
    borderRadius: rem(10),
    width: rem(40),
    height: rem(40),
    [breakpoints.up('md')]: {
      borderRadius: rem(16),
      width: rem(64),
      height: rem(64),
    },
  },
  yellow: {
    background: palette.supporting.amber[500],
  },
  blue: {
    background: palette.supporting.sky[500],
  },
  red: {
    background: palette.supporting.red[500],
  },
  green: {
    background: palette.supporting.green[500],
  },
}));
