import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

type Props = {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
};
export default makeStyles<Props>()(
  ({ breakpoints }, { top, left, right, bottom }) => ({
    container: {
      display: 'none',
      [breakpoints.up('md')]: {
        display: 'unset',
        position: 'absolute',
        top: `calc(${top > 0 ? 1 : -1} * ${rem(Math.abs(top))})`,
        left: `calc(${left > 0 ? 1 : -1} * ${rem(Math.abs(left))})`,
        right: `calc(${right > 0 ? 1 : -1} * ${rem(Math.abs(right))})`,
        bottom: `calc(${bottom > 0 ? 1 : -1} * ${rem(Math.abs(bottom))})`,
      },
    },
  })
);
