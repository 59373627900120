import { FC } from 'react';
import { useIntl } from 'react-intl';
import Image from 'next/image';

import { getImageFromCloudImage } from '@/utils/cloudFrontLoader';

import messages from './messages';

import useStyles from './styles';

const images = {
  image1: {
    url: 'https://sherpas-prod.s3.eu-west-3.amazonaws.com/landing/Image1LandingStepper.png',
    width: 415,
    height: 376,
  },

  image2: {
    url: 'https://sherpas-prod.s3.eu-west-3.amazonaws.com/landing/Image2LandingStepper.png',
    width: 366,
    height: 305,
  },

  image3: {
    url: 'https://sherpas-prod.s3.eu-west-3.amazonaws.com/landing/Image3LandingStepper.png',
    width: 435,
    height: 339,
  },

  image4: {
    url: 'https://sherpas-prod.s3.eu-west-3.amazonaws.com/landing/Image4LandingStepper.png',
    width: 385,
    height: 347,
  },
};

type Props = {
  stepIndex: number;
};

const StepImage: FC<Props> = ({ stepIndex }) => {
  const { formatMessage } = useIntl();
  const { url, width, height } = images[`image${stepIndex}`];
  const { classes } = useStyles();
  return (
    <Image
      src={url}
      width={width}
      height={height}
      loader={getImageFromCloudImage}
      quality={100}
      alt={formatMessage(messages[`altImage${stepIndex}`])}
      className={classes.image}
    />
  );
};

export default StepImage;
