import { FC, ReactElement } from 'react';
import { Typography } from '@mui/material';

type Props = {
  title: string | ReactElement;
  className: string;
};

const Title: FC<Props> = ({ title, className }) => (
  <Typography component="h3" variant="titleLg" className={className}>
    {title}
  </Typography>
);

export default Title;
