import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import Image from 'next/image';
import messages from 'src/components/LandingMozza/common/RatingGoogle/messages';
import useStyles from 'src/components/LandingMozza/common/RatingGoogle/styles';

import { GOOGLE_GMB_URL } from '@/components/LandingMozza/common/RatingGoogle/constants';
import { bodyLgBoldChunk } from '@/components/ReactIntlChunks/BoldChunk';
import { apiGetGoogleReviewsCount } from '@/shared/API/apiGetGoogleReviews';
import handleSendEventGTM from '@/utils/googleTagManagerHelper';

type Props = {
  className?: string;
  gtmEvent?: string;
  canStopRedirectToRating?: boolean;
};

const DEFAULT_REVIEWS_COUNT = 210;

const RatingGoogle: FC<Props> = ({
  className,
  gtmEvent,
  canStopRedirectToRating = false,
}) => {
  const { classes, cx } = useStyles({ canStopRedirectToRating });
  const { data } = useQuery(
    ['GooglePlaceReviewsCount'],
    apiGetGoogleReviewsCount,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      initialData: DEFAULT_REVIEWS_COUNT,
    }
  );

  const goToRatingPage = () => {
    if (!canStopRedirectToRating) {
      handleSendEventGTM(gtmEvent);
      window.open(GOOGLE_GMB_URL, '_blank').focus();
    }
  };

  return (
    <div
      className={cx(classes.container, className)}
      onClick={goToRatingPage}
      onKeyDown={goToRatingPage}
      role="button"
      tabIndex={0}
    >
      <div className={classes.google}>
        <div>
          <Image
            src="/images/icons/social/logo_google.png"
            width={95}
            height={32}
            alt="google"
            data-mozza="gPoint"
          />
        </div>
        <div>
          <Typography variant="bodyLg">
            <FormattedMessage
              {...messages.note}
              values={{
                bb: bodyLgBoldChunk,
                reviewsCount: data,
              }}
            />
          </Typography>
        </div>
      </div>
      <div className={classes.stars}>
        {new Array(5).fill(' ').map((_, index) => (
          <StarRoundedIcon key={index} />
        ))}
      </div>
    </div>
  );
};

export default RatingGoogle;
