import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'landingStepper.message.title',
    defaultMessage:
      'Planifiez votre premier cours<enter></enter><underlined>en quelques minutes</underlined> 🤓',
  },
  description: {
    id: 'landingStepper.message.description',
    defaultMessage:
      '4 étapes simples pour démarrer avec un de nos professeurs particuliers.  ',
  },
});
