import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { Typography } from '@mui/material';

const SectionTitle: FC<
  PropsWithChildren & HTMLAttributes<HTMLHeadingElement>
> = ({ children, className }) => (
  <Typography component="h2" variant="titleXl" className={className}>
    {children}
  </Typography>
);

export default SectionTitle;
