import { FC, PropsWithChildren } from 'react';
import useStyles from 'src/components/LandingMozza/common/ArticleHeader/styles';

const ArticleHeader: FC<PropsWithChildren> = ({ children }) => {
  const { classes } = useStyles();

  return <div className={classes.container}>{children}</div>;
};

export default ArticleHeader;
