import { Typography } from '@mui/material';

export const bodyLgBoldChunk = (chunks: never) => (
  <Typography variant="bodyLgBold" component="span">
    {chunks}
  </Typography>
);

export const bodyMdBoldChunk = (chunks: never) => (
  <Typography variant="bodyMdBold" color="text.secondary" component="span">
    {chunks}
  </Typography>
);
