import axios from 'axios';
import server from 'config';

export const apiGetGoogleReviewsCount = async (): Promise<number> => {
  const { data } = await axios.get<number>(
    `${server}google-place/public/reviews-count`,
    {}
  );
  return data;
};
