import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles<{ widthPercent: number }>()(
  (_, { widthPercent }) => ({
    container: {
      display: 'inline-block',
      position: 'relative',
    },
    svg: {
      position: 'absolute',
      bottom: `calc(-${rem(4)})`,
      left: '50%',
      width: `${widthPercent}%`,
      transform: 'translateX(-50%)',
    },
  })
);
