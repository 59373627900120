import { useEffect, useState } from 'react';

import useIsElementOnScreen, {
  UseIsElementOnScreenProps,
} from '@/hooks/animation/useIsElementOnScreen';

type Props = {
  y?: number;
  yPercent?: number;
  elementMargins?: UseIsElementOnScreenProps['elementMargins'];
};

const useIsElementBeyond = ({ y, yPercent, elementMargins }: Props) => {
  const { ref, elementTop } = useIsElementOnScreen({ elementMargins });
  const [isElementBeyond, setIsElementBeyond] = useState(false);

  useEffect(() => {
    const targetY = y || window.innerHeight * yPercent;

    const handleScroll = () => {
      if (!ref.current) return;

      setIsElementBeyond(elementTop > targetY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [elementTop, ref, y, yPercent]);

  return { ref, isElementBeyond };
};

export default useIsElementBeyond;
