import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ breakpoints, palette }) => ({
  steps: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(48),
    [breakpoints.up('md')]: {
      gap: rem(80),
    },
  },
  buttonContainer: {
    display: 'flex',
    alignSelf: 'center',
  },
  yellow: {
    color: palette.supporting.amber.main,
  },
  blue: {
    color: palette.supporting.sky.main,
  },
  red: {
    color: palette.supporting.red.main,
  },
  green: {
    color: palette.supporting.green.main,
  },
}));
