import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

type Props = {
  stickyTop: number;
  scalePercent: number;
};

export default makeStyles<Props>()(
  ({ palette, breakpoints }, { stickyTop, scalePercent }) => ({
    container: {
      position: 'sticky',
      top: rem(stickyTop),
      display: 'flex',
      borderRadius: rem(16),
      transition: 'all 0.4s',
      overflow: 'hidden',
    },
    scaleDown: {
      transform: `scale(${scalePercent})`,
    },
    leftSection: {
      padding: `${rem(32)} ${rem(24)}`,
      [breakpoints.up('md')]: {
        padding: rem(80),
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 0,
      },
    },

    rightSection: {
      display: 'none',
      [breakpoints.up('md')]: {
        display: 'flex',
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 0,
        justifyContent: 'center',
        alignItems: 'center',
        width: `37vw`,
      },
    },

    counter: {
      marginBottom: rem(24),
    },

    title: {
      marginBottom: rem(32),
      fontWeight: 400,
    },

    yellowLeftPanel: {
      backgroundColor: palette.supporting.amber.light,
    },
    yellowRightPanel: {
      background: `linear-gradient(180deg, ${palette.supporting.amber[900]} 0%, ${palette.supporting.amber.light} 100%)`,
    },
    blueLeftPanel: {
      backgroundColor: palette.supporting.sky.light,
    },
    blueRightPanel: {
      background: `linear-gradient(180deg, ${palette.supporting.sky[900]} 0%, ${palette.supporting.sky.light} 100%)`,
    },
    redLeftPanel: {
      backgroundColor: palette.supporting.red.light,
    },
    redRightPanel: {
      background: `linear-gradient(180deg, ${palette.supporting.red[900]} 0%, ${palette.supporting.red.light} 100%)`,
    },
    greenLeftPanel: {
      backgroundColor: palette.supporting.green.light,
    },
    greenRightPanel: {
      background: `linear-gradient(180deg, ${palette.supporting.green[900]} 0%, ${palette.supporting.green.light} 100%)`,
    },
  })
);
