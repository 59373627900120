import { defineMessages } from 'react-intl';

export default defineMessages({
  altImage1: {
    id: 'landingStepper.image.alt1',
    defaultMessage: 'Une prof de maths donnant des cours sur Les Sherpas',
  },
  altImage2: {
    id: 'landingStepper.image.alt2',
    defaultMessage: 'Création du profil Étudiant sur Les Sherpas',
  },
  altImage3: {
    id: 'landingStepper.image.alt3',
    defaultMessage: 'Le cours particulier en ligne d’essai est offert',
  },
  altImage4: {
    id: 'landingStepper.image.alt4',
    defaultMessage:
      'L’accompagnement et le suivi régulier par l’équipe des Shepas',
  },
});
