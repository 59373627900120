import { FC, PropsWithChildren } from 'react';

import PaddingContainerProps from '@/components/PaddingContainer/types';

import useStyles from './styles';

const PaddingContainer: FC<PropsWithChildren<PaddingContainerProps>> = ({
  children,
  withMaxWidth = false,
}) => {
  const { classes } = useStyles({ withMaxWidth });

  return <div className={classes.paddingContainer}>{children}</div>;
};

export default PaddingContainer;
