import { defineMessages } from 'react-intl';

export default defineMessages({
  title1: {
    id: 'landing.mozza.stepper.title1',
    defaultMessage:
      'Parcourez notre plateforme et <color>découvrez nos Sherpas</color>',
  },
  title2: {
    id: 'landing.mozza.stepper.title2',
    defaultMessage:
      '<color>Inscrivez-vous</color> pour 19,90 € de frais pédagogiques ',
  },
  title3: {
    id: 'landing.mozza.stepper.title3',
    defaultMessage: 'Profitez d’un cours <color>d’essai\u00A0offert</color>',
  },
  title4: {
    id: 'landing.mozza.stepper.title4',
    defaultMessage:
      'Optez pour la bonne formule <color>avec l’aide de notre équipe</color>',
  },

  description1: {
    id: 'landingStepper.step.content.message.description1',
    defaultMessage:
      'Chaque Sherpa est certifié et a été formé par notre équipe pédagogique. Les identités et les diplômes ont été vérifiés minutieusement.',
  },

  description2: {
    id: 'landingStepper.step.content.message.description2',
    defaultMessage:
      'Créez votre profil et laissez-vous guider par notre équipe d’experts qui vous trouvera le professeur parfait. Satisfait ou remboursé. ',
  },

  description3: {
    id: 'landingStepper.step.content.message.description3',
    defaultMessage:
      "Planifiez votre cours d'essai en ligne sur la plateforme Sherpa, avant de prendre un cours à domicile, en visio, ou dans le lieu de votre choix.",
  },

  description4: {
    id: 'landingStepper.step.content.message.description4',
    defaultMessage:
      'Après le cours d’essai, notre équipe vous propose une formule sur mesure et vous accompagne avec un suivi régulier des progrès réalisés.',
  },

  button: {
    id: 'landingMozza.stepper.button',
    defaultMessage: 'Découvrir nos professeurs',
  },
});
