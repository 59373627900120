import { FC, PropsWithChildren } from 'react';
import useStyles from 'src/components/LandingMozza/common/Cotillon/styles';

type Props = {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
};

const Cotillon: FC<PropsWithChildren<Props>> = ({
  children,
  top,
  left,
  right,
  bottom,
}) => {
  const { classes } = useStyles({ top, left, right, bottom });

  return <div className={classes.container}>{children}</div>;
};

export default Cotillon;
