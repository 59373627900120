import { useRouter } from 'next/router';
import { ParsedUrlQueryInput } from 'querystring';

import handleSendEventGTM from '@/utils/googleTagManagerHelper';

type Props = {
  route: string;
  event: string;
  query?: Record<string, unknown>;
  data?: Record<string, unknown>;
};

const useGoToPageWithEvent = () => {
  const { push } = useRouter();
  return ({ route, event, query = {}, data = {} }: Props) => {
    handleSendEventGTM(event, { option: query, ...data });
    push({ pathname: route, query: query as ParsedUrlQueryInput });
  };
};
export default useGoToPageWithEvent;
