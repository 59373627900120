import { makeStyles } from 'tss-react/mui';

import PaddingContainerProps from '@/components/PaddingContainer/types';

export default makeStyles<PaddingContainerProps>()(
  ({ breakpoints, spacing }, { withMaxWidth }) => ({
    paddingContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: `0 ${spacing(2)}`,
      gap: spacing(7.5),
      maxWidth: withMaxWidth ? spacing(180) : 'unset',
      margin: spacing(0, 'auto'),
      [breakpoints.up('md')]: {
        padding: `0 ${spacing(10)}`,
      },
    },
  })
);
