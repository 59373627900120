import { FC, HTMLAttributes } from 'react';

const CheckWithCircle: FC<HTMLAttributes<SVGElement>> = ({ className }) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_2849_3776)">
      <path
        d="M14.6667 8.38186V8.99519C14.6659 10.4328 14.2004 11.8316 13.3396 12.9831C12.4788 14.1345 11.2689 14.9768 9.89028 15.3845C8.51166 15.7921 7.03821 15.7431 5.68969 15.2449C4.34116 14.7467 3.18981 13.8259 2.40735 12.6199C1.62488 11.4139 1.25323 9.98723 1.34783 8.55273C1.44242 7.11823 1.99818 5.75274 2.93223 4.6599C3.86628 3.56707 5.12856 2.80544 6.53083 2.48862C7.9331 2.1718 9.40022 2.31675 10.7134 2.90186"
        stroke="#3E9A67"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6667 3.66187L8 10.3352L6 8.3352"
        stroke="#1A1919"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2849_3776">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.995117)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default CheckWithCircle;
