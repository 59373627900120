import { useEffect, useRef, useState } from 'react';

export type UseIsElementOnScreenProps = {
  elementMargins?: { top?: number; bottom?: number };
};

const useIsElementOnScreen = ({
  elementMargins,
}: UseIsElementOnScreenProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isElementOnScreen, setIsElementOnScreen] = useState(false);
  const [elementTop, setElementTop] = useState(0);

  const { top = 0, bottom = 0 } = elementMargins || {};

  useEffect(() => {
    const handleScroll = () => {
      if (!ref.current) return;
      const element = ref.current.getBoundingClientRect();

      const isElementVisible =
        element.top - top < window.innerHeight && element.bottom + bottom > 0;
      setIsElementOnScreen(isElementVisible);
      setElementTop(element.top);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [ref, top, bottom]);

  return { ref, isElementOnScreen, elementTop };
};

export default useIsElementOnScreen;
