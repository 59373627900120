import { FC } from 'react';
import { Typography } from '@mui/material';

import { StepVariant } from '@/components/LandingMozza/common/StartingSteps/StartingSteps.type';

import useStyles from './styles';

type Props = {
  stepVariant: StepVariant;
  stepIndex: number;
  className: string;
};

const Counter: FC<Props> = ({ stepVariant, stepIndex, className }) => {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.container, classes[stepVariant], className)}>
      <Typography variant="titleLg" color="white">
        {stepIndex}
      </Typography>
    </div>
  );
};

export default Counter;
