import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ breakpoints }) => ({
  image: {
    [breakpoints.up('md')]: {
      maxWidth: '80%',
      height: 'auto',
    },
  },
}));
