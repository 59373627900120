import { FC, ReactElement } from 'react';
import { Typography } from '@mui/material';

type Props = {
  content: string | ReactElement;
};

const Description: FC<Props> = ({ content }) => (
  <Typography component="p" variant="body2xl">
    {content}
  </Typography>
);

export default Description;
